import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Sessions from "../Sessions";
import Page from "../../../components/Page";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { getAllSessions } from "../../../core/repo/sessionRepo";
import { StartSessionContainer } from "../NewSession";
import Header from "./Header";
import Loader from "../../../components/Loader";
import useScrollHeader from "../../../hooks/useScrollHeader";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function QnA() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { selectedDomain } = useSelector((state) => state.domain);
  const { isSessionsLoading } = useSelector((state) => state.session);
  const { containerRef, showHeader } = useScrollHeader();

  const fetchConversations = async () => {
    try {
      await dispatch(getAllSessions(selectedDomain["id"], "qna"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDomain) fetchConversations();
  }, [selectedDomain]);

  return (
    <Page className={classes.root} title={"QnA"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header showHeader={showHeader} />
        <Box ref={containerRef} flex={1} overflow={"auto"}>
          <Box
            maxWidth={900}
            margin={"0 auto"}
            p={{ xs: 2, md: 3 }}
            pb={{ xs: 4, sm: 6 }}>
            <Box mb={7}>
              <Typography variant="h4">
                <span className={classes.gradientText}>QnA</span>
              </Typography>
            </Box>
            <StartSessionContainer selectedDomain={selectedDomain} />
            <Box mb={6} />
            <Sessions mode={"qna"} />
          </Box>
          {isSessionsLoading && <Loader />}
        </Box>
      </Box>
    </Page>
  );
}

export default QnA;
