export const SET_SELECTED_SESSION_SUCCESS =
  "@session/set-selected-session-success";
export const GET_SESSIONS_SUCCESS = "@session/get-sessions-success";
export const GET_SESSIONS_INITIATE = "@session/get-sessions-initiate";
export const GET_SESSIONS_FAILURE = "@session/get-sessions-failure";
export const GET_SESSION_MESSAGES_INITIATE =
  "@session/get-session-messages-initiate";
export const GET_SESSION_MESSAGES_SUCCESS =
  "@session/get-session-messages-success";
export const GET_SESSION_MESSAGES_FAILURE =
  "@session/get-session-messages-failure";
export const RESET_SESSION_MESSAGES_SUCCESS =
  "@session/reset_session_message_success";
export const UPDATE_SESSION_SUCCESS = "@session/update-session-success";
export const DELETE_SESSION_SUCCESS = "@session/delete-session-success";
export const CREATE_SESSION_SUCCESS = "@session/create-session-success";
export const RECORD_FEEDBACK_SUCCESS = "@session/record_feedback_success";
export const DELETE_FEEDBACK_SUCCESS = "@session/delete_feedback_success";
export const ADD_MESSAGE_SUCCESS = "@session/add_message_success";
export const SET_CHAT_MODE_SUCCESS = "@session/set_chat_mode_success";
export const SET_SELECTED_FILES_SUCCESS = "@session/set_selected_files_success";
export const SET_SELECTED_DATA_ANALYSIS_FILE_SUCCESS =
  "@session/set_selected_data_analysis_file_success";
export const SET_SELECTED_DOMAIN_SUCCESS =
  "@session/set_selected_domain_success";

export function setSelectedDomainSuccess(selectedDomain) {
  return {
    payload: { selectedDomain },
    type: SET_SELECTED_DOMAIN_SUCCESS,
  };
}

export function setSelectedDataAnalysisFileSuccess(selectedDataAnalysisFile) {
  return {
    payload: { selectedDataAnalysisFile },
    type: SET_SELECTED_DATA_ANALYSIS_FILE_SUCCESS,
  };
}

export function setSelectedFilesSuccess(selectedFiles) {
  return {
    payload: { selectedFiles },
    type: SET_SELECTED_FILES_SUCCESS,
  };
}

export function setChatModeSuccess(chatMode) {
  return {
    payload: { chatMode },
    type: SET_CHAT_MODE_SUCCESS,
  };
}

export function addMessageSuccess(message, parentMessage = null) {
  return {
    payload: { message, parentMessage },
    type: ADD_MESSAGE_SUCCESS,
  };
}

export function setSelectedSessionSuccess(session) {
  return {
    payload: { session },
    type: SET_SELECTED_SESSION_SUCCESS,
  };
}

export function getSessionsInitiate() {
  return {
    type: GET_SESSIONS_INITIATE,
  };
}

export function getSessionsSuccess(sessions) {
  return {
    payload: { sessions },
    type: GET_SESSIONS_SUCCESS,
  };
}

export function getSessionsFailure() {
  return {
    type: GET_SESSIONS_FAILURE,
  };
}

export function getSessionMessagesInitiate() {
  return {
    type: GET_SESSION_MESSAGES_INITIATE,
  };
}

export function getSessionMessagesSuccess(messages) {
  return {
    payload: { messages: messages },
    type: GET_SESSION_MESSAGES_SUCCESS,
  };
}

export function getSessionMessagesFailure() {
  return {
    type: GET_SESSION_MESSAGES_FAILURE,
  };
}

export function resetSessionMessagesSuccess() {
  return {
    type: RESET_SESSION_MESSAGES_SUCCESS,
  };
}

export function updateSessionSuccess(session) {
  return {
    type: UPDATE_SESSION_SUCCESS,
    payload: { session },
  };
}

export function deleteSessionSuccess(sessionId) {
  return {
    type: DELETE_SESSION_SUCCESS,
    payload: { sessionId },
  };
}

export function createSessionSuccess(session) {
  return {
    type: CREATE_SESSION_SUCCESS,
    payload: { session },
  };
}

export function recordFeedbackSuccess(feedback) {
  return {
    type: RECORD_FEEDBACK_SUCCESS,
    payload: { feedback },
  };
}

export function deleteFeedbackSuccess(messageId) {
  return {
    type: DELETE_FEEDBACK_SUCCESS,
    payload: { messageId },
  };
}
