import {
  createSessionSuccess,
  deleteFeedbackSuccess,
  deleteSessionSuccess,
  getSessionMessagesFailure,
  getSessionMessagesInitiate,
  getSessionsFailure,
  getSessionsInitiate,
  getSessionsSuccess,
  recordFeedbackSuccess,
  setSelectedSessionSuccess,
  updateSessionSuccess,
} from "../events/sessionEvents";
import sessionService from "../api/sessionService";

export function setSelectedSession(session) {
  return (dispatch) => {
    dispatch(setSelectedSessionSuccess(session));
  };
}

export function createSession(accountId, domainId, chatMode, fileId) {
  return async (dispatch) => {
    try {
      const response = await sessionService.createSession(
        accountId,
        domainId,
        chatMode,
        fileId
      );
      dispatch(createSessionSuccess({ ...response, title: "New Session" }));
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateSession(session) {
  return async (dispatch) => {
    try {
      await sessionService.updateSession(session);
      dispatch(updateSessionSuccess(session));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteSession(sessionId) {
  return async (dispatch) => {
    try {
      await sessionService.deleteSession(sessionId);
      dispatch(deleteSessionSuccess(sessionId));
    } catch (error) {
      throw error;
    }
  };
}

export function getAllSessions(domainId, chatMode) {
  return async (dispatch) => {
    try {
      dispatch(getSessionsInitiate());
      const response = await sessionService.getAllSessions(domainId, chatMode);
      dispatch(getSessionsSuccess(response["conversations"] || []));
      return response["conversations"] || [];
    } catch (error) {
      dispatch(getSessionsFailure());
      throw error;
    }
  };
}

export function getSessionMessages(sessionId) {
  return async (dispatch) => {
    try {
      dispatch(getSessionMessagesInitiate());
      const response = await sessionService.getSessionMessages(sessionId);
      return response;
    } catch (error) {
      dispatch(getSessionMessagesFailure());
      throw error;
    }
  };
}

export function recordFeeback(messageId, feedbackData) {
  return async (dispatch) => {
    try {
      await sessionService.recordFeedback(messageId, feedbackData);
      dispatch(recordFeedbackSuccess(feedbackData));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteFeedback(messageId) {
  return async (dispatch) => {
    try {
      await sessionService.deleteFeedback(messageId);
      dispatch(deleteFeedbackSuccess(messageId));
    } catch (error) {
      throw error;
    }
  };
}

export async function getPromptQuestions() {
  try {
    const response = await sessionService.getPromptQuestions();
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPipelineLogs(questionId) {
  try {
    const response = await sessionService.getPipelineLogs(questionId);
    return response;
  } catch (error) {
    throw error;
  }
}

export function getImage(imageURL) {
  return async (dispatch) => {
    try {
      const response = await sessionService.getImage(imageURL);
      return response;
    } catch (error) {
      throw error;
    }
  };
}
