import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  Grid,
  CircularProgress,
} from "@mui/material";
import { CloseRounded, Fullscreen } from "@mui/icons-material";
import PDFView from "../../../../components/PDFView";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import FullViewPDF from "../../components/FullViewPDF";
import { CurrentPagePlugin } from "../../../../components/PDFView/Toolbar";
import { SourcePDF } from "../../components/SourceDialog";
import { PDFDocument } from "pdf-lib";
import { useSnackbar } from "notistack";
import { getImage } from "../../../../core/repo/sessionRepo";
import { FETCH_IMAGE_URL } from "../../../../core/api/url";
import {
  getAccountDetails,
  getUserDetails,
} from "../../../../core/storage/localStorage";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    minHeight: "100%",
  },
}));

function EntityDialog({ entity, handleEntityChange }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const { file_id } = entity;
  const { files } = useSelector((state) => state.files);
  const file = files.find((f) => f["id"] === file_id);

  let source = { page: 0, coordinates: null, section: entity["title"] };

  return (
    file && (
      <>
        {isXS ? (
          <Dialog open={true} fullScreen classes={{ paper: classes.dialog }}>
            <SourcePDF
              file={file}
              source={source}
              handleSourceChange={handleEntityChange}
              entity={true}
            />
          </Dialog>
        ) : (
          <Grid
            item
            md={8}
            height={"100%"}
            sx={{ paddingRight: 3, paddingBottom: 3 }}>
            <SourcePDF
              file={{
                name: file["name"],
                file_url: entity["entity_url"],
              }}
              source={source}
              handleSourceChange={handleEntityChange}
              entity={true}
            />
          </Grid>
        )}
      </>
    )
  );
}

export default EntityDialog;
