import React, { useState } from "react";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";

function FullScreenSourceDialog({ fullScreenSource }) {
  const theme = useTheme();
  const { name, csv_file_map } = fullScreenSource;
  const csvList = Object.keys(csv_file_map);
  const [selectedCSV, setSelectedCSV] = useState(csvList[0]);

  return (
    <Grid
      item
      md={12}
      height={"100%"}
      sx={{ paddingRight: 3, paddingBottom: 3 }}>
      <Box
        height={"100%"}
        sx={{
          borderRadius: 4,
          border: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
        }}>
        <Box
          px={2}
          py={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography noWrap>{name}</Typography>
        </Box>
        <Divider />
        <Box sx={{ flex: 1, overflow: "auto" }}></Box>
      </Box>
    </Grid>
  );
}

export default FullScreenSourceDialog;
