import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Page from "../../../components/Page";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  getAllSessions,
  getSessionMessages,
} from "../../../core/repo/sessionRepo";
import Header from "./Header";
import Loader from "../../../components/Loader";
import { formQuestionAnswerPairs } from "../Messages";
import EntityDialog from "./components/EntityDialog";
import Query from "../Messages/Query";
import MessageLoader from "./components/MessageLoader";
import { getEntity } from "../../../core/repo/chatRepo";
import { addMessageSuccess } from "../../../core/events/sessionEvents";
import { usePostHog } from "posthog-js/react";
import { ENTITY_CLICK } from "../../../utils/posthogEvents";
import ScrollToBottom from "../components/ScrollToBottom";
import { getSessionMessagesSuccess } from "../../../core/events/sessionEvents";
import { getAIModel, getUserDetails } from "../../../core/storage/localStorage";
import { v4 as uuidv4 } from "uuid";
import MessageResults from "../Messages/Results";
import useScrollHeader from "../../../hooks/useScrollHeader";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  gradientText: {
    background: theme.palette.accentGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

function ChecklistDiagram() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const messagesEndRef = useRef();
  const messageRefs = useRef({});
  const queryRef = useRef();
  const queryContentRef = useRef();
  const user = getUserDetails();
  const [queryContainerHeight, setQueryContainerHeight] = useState(0);
  const [entity, setEntity] = useState(null);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [model, setModel] = useState(() => getAIModel());
  const { containerRef: messagesRef, showHeader } = useScrollHeader();
  const { selectedDomain } = useSelector((state) => state.domain);
  const {
    messages,
    sessions,
    isSessionsLoading,
    selectedFiles,
    isMessagesLoading,
  } = useSelector((state) => state.session);

  const fetchConversations = async () => {
    try {
      const sessionsResponse = await dispatch(
        getAllSessions(selectedDomain["id"], "checklist_diagram")
      );
      let processedMessages = [];
      if (sessionsResponse.length > 0) {
        const sessionId = sessionsResponse[0]["id"];
        const response = await dispatch(getSessionMessages(sessionId));
        processedMessages = formQuestionAnswerPairs(response["messages"] || []);
      }
      dispatch(getSessionMessagesSuccess(processedMessages));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDomain) fetchConversations();
  }, [selectedDomain]);

  //code to get the height of the query container which is used to decide the height of the last message
  useEffect(() => {
    const queryContainer = queryRef.current;

    if (queryContainer) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          setQueryContainerHeight(newHeight);
        }
      });

      resizeObserver.observe(queryContainer);

      return () => {
        resizeObserver.unobserve(queryContainer);
      };
    }
  }, []);


  //whenever user updates the model inside account dialog, model in this page is not updated becasue model is stored inside localstorage.
  //Inorder to trigger storage change, storage event is dispatched inside account dialog.
  //This useeffect listens to the event and updates the model if there is any change.
  useEffect(() => {
    const handleStorage = () => {
      const model = getAIModel();
      setModel(model);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length]);

  const handleEntityChange = (entity) => {
    setEntity(entity);
    posthog.capture(ENTITY_CLICK);
  };

  async function handleEntity(query) {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);

    setIsAnswerLoading(true);
    setQuery(query);
    let session = sessions ? sessions[0] : null;

    if (!session) {
      try {
        session = await dispatch(
          createSession(
            user["id"],
            selectedDomain ? selectedDomain["id"] : null,
            "checklist_diagram"
          )
        );
      } catch (err) {
        console.log(err);
      }
    }

    let queryId = uuidv4();
    let fileIds = selectedFiles
      .filter(
        (file) => !selectedDomain || selectedDomain["id"] === file["domain_id"]
      )
      .map((f) => f["id"]);
    try {
      const entities = await dispatch(
        getEntity(
          {},
          query,
          session["id"],
          queryId,
          null,
          true,
          fileIds,
          model,
          session["domain_id"]
        )
      );
      let { query_object, answer_object } = entities;
      dispatch(
        addMessageSuccess({ query: query_object, answer: answer_object })
      );
    } catch (err) {
      console.log(err);
      //   streamApiError(err, queryId, query);
    }
    setIsAnswerLoading(false);
  }

  return (
    <Page className={classes.root} title={"Checklist/Diagram"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        <Header showHeader={showHeader} />
        <Box flex={1} overflow={"auto"}>
          <Grid container height={"100%"}>
            <Grid
              item
              sm={entity ? 4 : 12}
              height={"100%"}
              width={"100%"}
              position={"relative"}>
              <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                <Box ref={messagesRef} flex={1} overflow={"auto"}>
                  <Box
                    p={{ xs: 2, sm: 3 }}
                    pb={{ xs: 4, sm: 6 }}
                    maxWidth={900}
                    margin={"0 auto"}>
                    <Box mb={7}>
                      <Typography variant="h4" color={"textPrimary"}>
                        <span className={classes.gradientText}>
                          Checklist/Diagram
                        </span>
                      </Typography>
                    </Box>
                    <MessageResults
                      source={entity}
                      messages={messages}
                      handleEntityChange={handleEntityChange}
                      messagesRef={messagesRef}
                      messageRefs={messageRefs}
                      queryContainerHeight={queryContainerHeight}
                      isStreaming={isAnswerLoading}
                    />
                    {isAnswerLoading && <MessageLoader query={query} />}
                    <Box ref={messagesEndRef} />
                  </Box>
                </Box>
                <ScrollToBottom
                  messagesRef={messagesRef}
                  messagesEndRef={messagesEndRef}
                />
                <Box
                  width={"100%"}
                  maxWidth={900}
                  margin={"0 auto"}
                  px={{ xs: 2, sm: 3 }}
                  pt={{ xs: 1, sm: 3 }}
                  pb={{ xs: 1, sm: 3 }}>
                  <Query
                    ref={queryContentRef}
                    handleStreamMessages={handleEntity}
                    isStreaming={isAnswerLoading}
                    queryRef={queryRef}
                  />
                </Box>
              </Box>
            </Grid>
            {entity && (
              <EntityDialog
                entity={entity}
                handleEntityChange={handleEntityChange}
              />
            )}
          </Grid>
        </Box>
        {(isSessionsLoading || isMessagesLoading) && <Loader />}
      </Box>
    </Page>
  );
}

export default ChecklistDiagram;
