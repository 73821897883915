import React, { useState, useEffect, useRef } from "react";
import Page from "../../../../components/Page";
import { makeStyles } from "tss-react/mui";
import { Box, Grid } from "@mui/material";
import Header from "./Header";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { DATA_ANALYSIS_MODE_URL } from "../../../../utils/routes";
import {
  getAllSessions,
  getSessionMessages,
} from "../../../../core/repo/sessionRepo";
import { formQuestionAnswerPairs } from "../../Messages";
import { getSessionMessagesSuccess } from "../../../../core/events/sessionEvents";
import ScrollToBottom from "../../components/ScrollToBottom";
import Query from "../../Messages/Query";
import MessageLoader from "./MessageLoader";
import Loader from "../../../../components/Loader";
import MessageResults from "../../Messages/Results";
import { v4 as uuidv4 } from "uuid";
import { getQueryTabularData } from "../../../../core/repo/chatRepo";
import { getAIModel } from "../../../../core/storage/localStorage";
import {
  addMessageSuccess,
  updateSessionSuccess,
} from "../../../../core/events/sessionEvents";
import { usePostHog } from "posthog-js/react";
import { SOURCE_CLICK } from "../../../../utils/posthogEvents";
import SourceDialog from "./SourceDialog";
import FullScreenSourceDialog from "./FullScreenSourceDialog";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    overflow: "scroll",
  },
}));

function Messages() {
  const posthog = usePostHog();
  const { classes } = useStyles();
  const { sessionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryRef = useRef();
  const queryContentRef = useRef();
  const messagesRef = useRef();
  const messagesEndRef = useRef();
  const messageRefs = useRef({});
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [queryContainerHeight, setQueryContainerHeight] = useState(0);
  const [model, setModel] = useState(() => getAIModel());
  const [source, setSource] = useState(null);
  const [fullScreenSource, setFullScreenSource] = useState(null);
  const { selectedDomain } = useSelector((state) => state.domain);
  const { messages, isMessagesLoading, sessions, isSessionsLoading } =
    useSelector((state) => state.session);
  const { completedFiles } = useSelector((state) => state.files);
  let session = sessions.find((s) => s["id"] === sessionId);

  async function fetchMessages() {
    try {
      let tempSessions = sessions;
      if (sessions.length === 0) {
        tempSessions = await dispatch(
          getAllSessions(selectedDomain["id"], "data_analysis")
        );
      }
      const isSessionExists = checkIfSessionExists(tempSessions);
      if (!isSessionExists) {
        enqueueSnackbar("You don't have access to this page.", {
          variant: "error",
        });
        history.push({ pathname: DATA_ANALYSIS_MODE_URL });
        return;
      }
      const response = await dispatch(getSessionMessages(sessionId));
      const processedMessages = formQuestionAnswerPairs(
        response["messages"] || []
      );
      dispatch(getSessionMessagesSuccess(processedMessages));
      if (processedMessages.length === 0) {
        const session = tempSessions.find((s) => s["id"] === sessionId);
        const file = completedFiles.find((f) => f["id"] === session["file_id"]);
        if (file["csv_file_map"])
          setSource({
            section: file["name"],
            csv_file_map: file["csv_file_map"],
            mainFile: true,
          });
      } else {
        const lastMessageAnswer =
          processedMessages[processedMessages.length - 1]["answer"];
        const citations =
          lastMessageAnswer["content_object"]["citations"] || [];
        if (citations.length > 0) {
          const citation = citations[0];
          const tabularData = JSON.parse(
            lastMessageAnswer["content_object"]["text"]
          );
          setSource({
            ...citation,
            csv_file_map: {
              [tabularData["sheet"]]: tabularData["csv_file_path"],
            },
            selected_row_ids: tabularData["selected_row_ids"],
            columns: tabularData["columns"],
            created_at: lastMessageAnswer["created_at"],
            jsonQueryObject: tabularData["json_query_object"],
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  // console.log(messages);

  useEffect(() => {
    selectedDomain && fetchMessages();
  }, [selectedDomain]);

  //code to get the height of the query container which is used to decide the height of the last message
  useEffect(() => {
    const queryContainer = queryRef.current;

    if (queryContainer) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          setQueryContainerHeight(newHeight);
        }
      });

      resizeObserver.observe(queryContainer);

      return () => {
        resizeObserver.unobserve(queryContainer);
      };
    }
  }, []);

  //whenever user updates the model inside account dialog, model in this page is not updated becasue model is stored inside localstorage.
  //Inorder to trigger storage change, storage event is dispatched inside account dialog.
  //This useeffect listens to the event and updates the model if there is any change.
  useEffect(() => {
    const handleStorage = () => {
      const model = getAIModel();
      setModel(model);
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 10);
    }
  }, [messages.length]);

  function checkIfSessionExists(sessions) {
    const session = sessions.find((s) => s["id"] === sessionId);
    return session ? true : false;
  }

  async function handleQueryTabularData(query) {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);

    setIsAnswerLoading(true);
    setQuery(query);
    let queryId = uuidv4();
    let fileIds = [session["file_id"]];
    try {
      const entities = await dispatch(
        getQueryTabularData(
          {},
          query,
          session["id"],
          queryId,
          null,
          true,
          fileIds,
          model,
          session["domain_id"]
        )
      );

      let { query_object, answer_object, conversation_title } = entities;
      if (session["title"] === "New Session") {
        dispatch(
          updateSessionSuccess({ ...session, title: conversation_title })
        );
      }
      dispatch(
        addMessageSuccess({ query: query_object, answer: answer_object })
      );
      try {
        const citations = answer_object["content_object"]["citations"] || [];
        if (citations.length > 0) {
          const citation = citations[0];
          const tabularData = JSON.parse(
            answer_object["content_object"]["text"]
          );
          handleSourceChange({
            ...citation,
            csv_file_map: {
              [tabularData["sheet"]]: tabularData["csv_file_path"],
            },
            selected_row_ids: tabularData["selected_row_ids"],
            columns: tabularData["columns"],
            created_at: answer_object["created_at"],
            jsonQueryObject: tabularData["json_query_object"],
          });
        }
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
    setIsAnswerLoading(false);
  }

  const handleSourceChange = (source) => {
    setSource(source);
    posthog.capture(SOURCE_CLICK);
  };

  return (
    <Page className={classes.root} title={"Data Analysis Messages"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        position={"relative"}>
        {session && (
          <Header
            session={session}
            source={source}
            handleSourceChange={handleSourceChange}
          />
        )}
        <Box flex={1} overflow={"auto"}>
          <Grid container height={"100%"}>
            <Grid
              item
              sm={source ? 4 : 12}
              height={"100%"}
              width={"100%"}
              position={"relative"}>
              <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                <Box ref={messagesRef} flex={1} overflow={"auto"}>
                  <Box
                    p={{ xs: 2, sm: 3 }}
                    pb={{ xs: 4, sm: 6 }}
                    maxWidth={900}
                    margin={"0 auto"}>
                    <MessageResults
                      source={source}
                      messages={messages}
                      handleSourceChange={handleSourceChange}
                      messagesRef={messagesRef}
                      messageRefs={messageRefs}
                      queryContainerHeight={queryContainerHeight}
                      isStreaming={isAnswerLoading}
                    />
                    {isAnswerLoading && (
                      <MessageLoader
                        numOfMessages={messages.length}
                        query={query}
                      />
                    )}
                    <Box ref={messagesEndRef} />
                  </Box>
                </Box>
                <ScrollToBottom
                  messagesRef={messagesRef}
                  messagesEndRef={messagesEndRef}
                />
                <Box
                  width={"100%"}
                  maxWidth={900}
                  margin={"0 auto"}
                  px={{ xs: 2, sm: 3 }}
                  pt={{ xs: 1, sm: 3 }}
                  pb={{ xs: 1, sm: 3 }}>
                  <Query
                    ref={queryContentRef}
                    handleStreamMessages={handleQueryTabularData}
                    isStreaming={isAnswerLoading}
                    queryRef={queryRef}
                    mode="data_analysis"
                  />
                </Box>
              </Box>
            </Grid>
            {source && (
              <SourceDialog
                fileId={session ? session["file_id"] : null}
                source={source}
                isAnswerLoading={isAnswerLoading}
                handleSourceChange={handleSourceChange}
              />
            )}
          </Grid>
        </Box>
        {(isSessionsLoading || isMessagesLoading) && <Loader />}
      </Box>
    </Page>
  );
}

export default Messages;
